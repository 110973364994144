// Body TiSch@storeconn
$body-bg: #F5F5F5;
$body-color: #0F0F0F;

// Typography
$font-family-sans-serif: 'futura-pt', sans-serif;
$font-size-base: 1.25rem;
$line-height-base: 1.6;

// Colors

$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #EC6608;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Custom Colors TiSch@storeconn

$gray-200: #eaeaea;

$sizes: () !default;
$sizes: map-merge(
    (
      10: 10%,
      20: 20%,
      25: 25%,
      30: 30%,
      40: 40%,
      50: 50%,
      60: 60%,
      70: 70%,
      75: 75%,
      80: 80%,
      90: 90%,
      100: 100%,
      auto: auto
    ),
    $sizes
);

$modal-fade-transform: scale(.8);
$input-focus-width: .2rem !default;
$input-focus-color: rgba($orange, .25) !default;
$input-focus-border-color: rgba($orange, .5) !default;
$input-focus-box-shadow: 0 0 0 $input-focus-width $input-focus-color !default;
