#result-header {
  color: #0F0F0F;
  font-family: futura-pt-bold, sans-serif;
  font-weight: 300;
}
#result-header h1 {
  font-weight: 700;
  line-height: 1.4;
  color: #0F0F0F;
  margin-bottom: 1rem;
}
#result-header hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
#result-header p {
  font-family: futura-pt, sans-serif;
}
#result-header p span:last-of-type {
  color: #EC6608;
}
#result-list h5 {
  color: #EC6608;
}
#result-list h6 {
  font-weight: 700;
  font-size: 1.1rem;
}
#result-list span {
  font-weight: 300;
  font-size: 1rem;
}
#result-list p {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.4rem;
}
#result-list a {
  right: 20px;
  top: 20px;
}
#result-list input[type=checkbox] + label {
  color: #0F0F0F;
  background: #FFFFFF;
  transition: all 200ms ease;
}
#result-list input[type=checkbox] + label a {
  color: #EEE;
}
#result-list input[type=checkbox]:checked + label {
  color: #FFF;
  background: #EC6608 !important;
  transition: all 200ms ease;
}
#result-list input[type=checkbox]:checked + label a {
  color: #FFF;
  transition: all 200ms ease;
}
#result-details h5 {
  color: #EC6608;
}
#result-details strong {
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.4rem;
}
#result-details p {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.4rem;
}
#result-details ul li {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.4rem;
}

