#debug {
  font-size: 14px;
}
#debug div {
  display: inline-block;
}
#debug strong {
}
#debug span {
  display: inline-block;
  padding: 20px 20px 0 6px;
}
