.custom-label {
  cursor: pointer;
}
.custom-label span {
  font-size: 1.1rem;
  line-height: 1.2rem;
}
.custom-image {
  filter: grayscale(100%);
  opacity: .4;
  transition: all 500ms ease;
}
.custom-image:hover,
.custom-image:active,
.custom-image:focus,
.custom-image.active {
  filter: none;
  opacity: 1;
  transition: all 500ms ease;

}
.custom-image-shadow:hover,
.custom-image-shadow:active,
.custom-image-shadow:focus,
.custom-image-shadow.active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.custom-select {
    border-radius: 0;
    border: 0;
    height: 52px;
    font-size: 1.4rem;
    color: #0F0F0F;
    padding: 0 2rem;
    background: #eaeaea url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.636' height='11.015' viewBox='0 0 17.636 11.015'%3E%3Cg transform='translate(0 11.015) rotate(-90)'%3E%3Crect width='12.462' height='3.116' transform='translate(0 8.812) rotate(-45)'/%3E%3Crect width='12.462' height='3.116' transform='translate(2.203 6.621) rotate(45)'/%3E%3C/g%3E%3C/svg%3E") no-repeat right 1.6rem center;
    background-size: 12px;
}
.custom-select:focus {
    border-color: #faae79;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 102, 8, 0.25);
}
.custom-select option:checked {
    color: #6F6F6F;
}
label + p {
  font-size: 1.1rem;
  font-weight: normal;
}
.btn-round {
  width: 80%;
  padding-bottom: 80%;
  position: relative;
}
.btn-round div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #ffffff;
  background-color: #6F6F6F;
  border-radius: 50%;
  transition: all 500ms ease;
}
.btn-rectangle {
  width: 80%;
  padding-bottom: 80%;
  position: relative;
}
.btn-rectangle div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #ffffff;
  background-color: #6F6F6F;
  transition: all 500ms ease;
}
.btn-round div:hover, .btn-rectangle div:hover {
  background-color: #EC6608;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: all 500ms ease;
}
.btn-active {
  background-color: #EC6608 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: all 500ms ease;
}
.btn {
  padding: 1rem 1.25rem;
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
  letter-spacing: unset;
  border: 0;
  font-size: 1rem;
  z-index: 1;
}

.btn-configurator {
  color: #ffffff;
  background-color: #EC6608;
  border-color: #EC6608;
  position: relative;
  transition: all 0.5s ease;
}
.btn-configurator:hover {
   color: #ffffff;
   border-color: #bb5106;
   background: #3c3c3b;
 }
.btn-configurator:hover::after {
  background: #3c3c3b;
  transform-origin: bottom left;
  transform: skew(0deg, 0deg);
}
.btn-configurator::after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #EC6608;
  transform-origin: bottom left;
  transform: skew(-35.5deg, 0deg);
  transition: all 0.5s ease;
}
.btn-configurator-left {
  color: #ffffff;
  background-color: #3c3c3b;
  border-color: #bb5106;
  position: relative;
  transition: all 0.5s ease;
}
.btn-configurator-left:hover {
   color: #ffffff;
   border-color: #3c3c3b;
   background: #bb5106;
 }
.btn-configurator-left:hover::after {
  background: #EC6608;
  transform-origin: bottom left;
  transform: skew(0deg, 0deg);
}
.btn-configurator-left::after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #3c3c3b;
  transform-origin: bottom left;
  transform: skew(35.5deg, 0deg);
  transition: all 0.5s ease;
}
