@media only screen and (max-width: 1199.98px) {
  header ul li a {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 991.98px) {
  header ul li a {
    font-size: 1rem;
  }
  .custom-select {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 767.98px) {
  header {
    height: 82px;
  }
  header div {
    height: 50%;
  }
  header ul li.list-inline-item:not(:last-child) {
    border-right: 1px solid;
    border-color: rgba(255, 255, 255, 0.2);
    padding-right: .5rem;
  }
  header ul li a {
    font-size: 0.9rem;
    margin-left: 0;
  }
  main {
    font-size: 1rem;
    padding-top: 90px;
  }
  .custom-select {
    font-size: 1.2rem;
  }
  .btn {
    padding: .4rem .4rem;
    font-family: futura-pt, sans-serif;
    font-weight: 300;
    font-size: .8rem;
  }
  .custom-label span {
    font-size: .9rem;
    line-height: 1rem;
  }
  footer {
    height: 40px;
  }
  footer i {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 575.98px) {
  header ul li.list-inline-item:not(:last-child) {
    border-right: 1px solid;
    border-color: rgba(255, 255, 255, 0.2);
    padding-right: .5rem;
  }
  header ul li a {
    font-size: 0.7rem;
    margin-left: 0;
  }
}
.preload-application {
  background:
    url('/img/kloeber-logo.svg') no-repeat -9999px -9999px,
    url('/img/Entwaesserung_400x400.jpg') no-repeat -9999px -9999px,
    url('/img/Be-_und_Entlueftung_400x400.jpg') no-repeat -9999px -9999px,
    url('/img/Dachdurchfuehrung_400x400.jpg') no-repeat -9999px -9999px,
;
}
