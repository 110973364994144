html{
  scroll-behavior: smooth;
}
body {
  font-weight: 300;
  line-height: 1.8;
  font-family: futura-pt, sans-serif;
  animation: fadeInAnimation ease 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(236, 102, 8, 0.25);
}

#header, #footer {
  z-index: 10;
}
header {
  transition: background 0.3s, height 0.3s ease;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: #3c3c3b;
  box-shadow: none;
  height: 86px;
}

header div {
  height: 100%;
}

header div > a:before {
  display: block;
  content: '';
  height: 25%;
}

header a img {
  height: 50%;
  width: auto;
  max-height: 170px;
}

header nav:before {
  display: block;
  content: '';
  height: 25%;
}

header ul li.list-inline-item:not(:last-child) {
  border-right: 1px solid;
  border-color: rgba(255, 255, 255, 0.2);
  padding-right: 1rem;
}

header ul li a {
  color: #EC6608 !important;
  font-family: futura-pt-bold, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.25rem;
  display: inline;
  margin-left: 1rem;
  margin-right: 0;
}

main {
  font-size: 1.25rem;
  padding-top: 100px;
  padding-bottom: 240px;
}

#thirdpartycookies {
  display: none;
}

main section.form-section {
  background: #ffffff;
}

footer {
  background-image: url("/img/footer.jpg");
  background-position: center center;
  background-size: cover;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
}

footer:before {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
footer i {
  font-size: 2rem;
}
