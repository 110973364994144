/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
#order-list h6 {
  font-weight: 700;
  font-size: 1rem;
}
#order-list strong {
  font-weight: 700;
  font-size: 1rem;
  color: #EC6608;
}
#order-list p {
  font-weight: 300;
  font-size: .9rem;
}
#order-list i {
  color: #EC6608;
  font-size: 1.8rem;
  z-index: 1000;
}
#order-list input {
  border: none !important;
  text-align: center;
  width: 40px;
  background: #FFFFFF;
}
.input-group {
  border: 1px solid #EC6608 !important;
}
.input-group a {
  display: block;
  width: 40px;
  text-decoration: none;
  color: #FFFFFF;
  background: #EC6608;
}
#widget-shopping-list {
  display: inline-block;
  position: absolute;
  right: 6px;
  top: 6px;
  min-width: 80px;
  height: 40px;
  border: 1px solid #EC6608;
  color: #EC6608;
  font-family: futura-pt-bold,sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  background: #FFFFFF;
}
#widget-shopping-list a {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
#widget-shopping-list a span {
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;
  color: #EC6608;
}
#widget-shopping-list a i {
  margin-right: 12px;
  color: #EC6608;
}
